@use "../config/" as *;

// btn-cart extend
%btn-cart {
    border-radius: var(--space-1);
    @extend %primary-300-bg;
    @extend %p-3-2;
    @extend %text-center;

    .btn-text {
        @extend %secondary-800;
        @extend %uppercase;
        @extend %font-10-pm;

        @include mq(col-lg) {
            font-size: 1.2rem;
        }
    }

}

// product count extend
%product-count{
    border-radius: var(--space-1);
    @include border(1, neutral-1200);
    @extend %p-2;
    @extend %flex-sb-c;
    @extend %gap-5;

    .count-value {
        @extend %font-12-psb;
        @extend %neutral-1300;
    }

    .decrement-count,
    .increment-count {
        height: 1.8rem;
        width: 1.8rem;

        .btn-txt {
            @extend %d-block;
            @extend %w-100;
            @extend %font-0;
        }
    }

    .decrement-count {
        .btn-txt::before {
            @include icon(minus, 12);
        }
    }

    .increment-count {
        .btn-txt::before {
            @include icon(add, 12);
        }
    }
}

// Article Item - Card Styling
.article {
    &-list {
        @extend %flex;
    }

    &-item {
        width: calc((100% - var(--space-4)) / 2);
        border-radius: 0.5rem;
        @extend %p-3;
        @extend %neutral-50-bg;
    }

    &-thumbnail {
        .img-box {
            @include border(1, neutral-1100);
            @extend %pure-white-900-bg;

            .image {
                object-fit: contain;
                @extend %p-3-2;
            }
        }
    }

    &-content {
        @extend %mt-4;
    }

    &-title {
        @include truncate-text(2, 40);
        @extend %font-12-pb;
        @extend %neutral-800;
    }

    &-meta {
        @extend %mt-2;

        .meta-author {
            @extend %font-10-pm;
            @extend %neutral-500;
        }

        .meta-date,
        .meta-category {
            @extend %d-none;
        }
    }

    &-footer {
        @extend %mt-6;
        @extend %flex-column;
        @extend %gap-3;

        .price {
            &-section{
                @extend %flex-n-c;
                @extend %gap-1;
            }
            &-previous {
                text-decoration: line-through;
                @extend %font-12-pr;
                @extend %neutral-600;
            }

            &-current {
                @extend %font-14-pb;
                @extend %neutral-800;
            }
        }

        .btn-cart {
            @extend %btn-cart;
        }
        .product-count{
            @extend %product-count;
        }

    }
}
.iPhone {
    .article-title {
        height: 3.2rem;
    }
}
// View Cart Strip
.cart {
    &-section {
        height: 12rem;
        bottom: var(--header-height);
        z-index: var(--z-cart);
        background-image: linear-gradient(to top, var(--neutral-900), transparent);
        @extend %flex-c-fe;
        @extend %w-100;
        @extend %pos-bl-fix;
    }

    &-wrapper {
        width: 90%;
        height: 5.7rem;
        margin: 0 auto var(--space-4);
        border-radius: var(--space-1);
        @extend %primary-50-bg;
        @extend %p-3-5;
        @extend %flex-sb-c;

        .product-total-count {
            @extend %font-12-pb;
            @extend %neutral-500;
            @extend %uppercase;
        }

        .price {
            @extend %font-16-pb;
            @extend %neutral-800;

            &-text {
                @extend %neutral-500;
                @extend %font-10-pr;
            }
        }

        .btn-view-cart {
            @extend %flex;
            @extend %btn-cart;
        }


    }
}
.btn-loading {
    pointer-events: none;
    @extend %relative;
    &::before {
        content: "";
        width: 2rem;
        height: 2rem;
        margin: auto;
        border: .4rem solid var(--neutral-50);
        border-top-color: var(--secondary-900);
        animation: button-loading-spinner 1s ease infinite;
        @extend %circle-radius;
        @extend %pos-center;
    }
    .btn-text {
        visibility: hidden;
    }
}
.btn-disabled {
    opacity: .5;
    pointer-events: none;
}
// Media Query from Tablet
@include mq(col-md) {
    .article {
        &-item {
            width: calc((100% - var(--space-8)) / 3);
        }
    }
}

// Media Query from Small screen Laptop
@include mq(col-lg) {
    .article {
        &-wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: var(--space-5);
        }

        &-item {
            padding: var(--space-4);
        }

        &-thumbnail {
            .img-box {
                .image {
                    padding: var(--space-6) var(--space-2);
                }
            }
        }

        &-content {
            margin-top: 0;
        }

        &-title {
            font-size: 1.6rem;
        }

        &-meta {
            .meta-author {
                font-size: 1.2rem;
            }
        }

        &-footer {
            margin-top: 0;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .price {
                &-previous {
                    font-size: 1.4rem;
                }

                &-current {
                    font-size: 1.6rem;
                }
            }
        }
    }

    .cart {
        &-wrapper {
            width: 60rem;
        }

        &-section {
            bottom: 0;
        }
    }
}