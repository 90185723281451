@use "../config/" as *;
@forward "../shop/shop-common";
body {
    @extend %pure-white-900-bg;
}
.waf-wp-shop-listing {
    @extend %pb-5;
    .waf-select-box {
        .selected-title {
            &::after {
                @include icon(p-arrow-down, 14);
            }
        }
    }
    .layout-wrapper {
        @extend %px-4;
    }
    .waf-head {
        .head-wrap {
            flex-wrap: wrap;
            @extend %py-7;
            @extend %gap-5;
            .title {
                @include truncate-text(2, 50);
                @extend %w-70;
                @extend %font-20-pb;
            }
            .result-wrap {
                .result {
                    @extend %font-12-pr;
                    @extend %neutral-600;
                }
            }
            .head-tab {
                @extend %d-none;
            }
        }
        .sort-filter {
            @extend %p-0;
            .waf-select-box {
                @extend %p-0;
                .select-box-wrap {
                    max-height: 0;
                    width: 18rem;
                    overflow-y: auto;
                    box-shadow: 0 0 0.7rem 0 clr(neutral-900, 3);
                    transition: max-height 0.5s ease-out;
                    @include position(2.5rem, 0, null, unset);
                    @extend %flex;
                    @extend %pure-white-900-bg;
                    @extend %half-radius;
                }
                &.active {
                    .selected-title::after {
                        rotate: 0deg;
                    }
                }
                &::before {
                    content: unset;
                }
            }
            .dropdown-wrap {
                @extend %flex-u-c;
                @extend %gap-2;
            }
            .dropdown-label {
                @extend %font-12-pr;
                @extend %neutral-600;
                @extend %capitalize;
            }
            .selected-title {
                .selected-text {
                    @extend %font-12-pb;
                    @extend %capitalize;
                }
                &[aria-expanded=true] {
                    &::after {
                        rotate: 180deg;
                    }
                }
                &::after {
                    right: 0;
                }
            }
            .selected-title[aria-expanded=true] ~ .select-box-wrap {
                max-height: 18rem;
            }
            .select-list {
                @extend %p-3-4;
                .list-item {
                    text-transform: capitalize;
                    @extend %p-0;
                    @extend %font-14-pm;
                    @extend %neutral-800;
                }
            }
            &::before {
                content: unset;
            }
        }
    }
    .article {
        &-list {
            @extend %flex-wrap;
            @extend %gap-4;
        }
        &-item {
            .dropdown-wrap {
                @extend %my-2;
            }
            .select-dropdown {
                border: .1rem solid var(--neutral-1000);
                outline: none;
                height: 3.5rem;
                cursor: pointer;
                @extend %neutral-800;
                @extend %half-radius;
                @extend %p-2;
                @extend %w-100;
                @extend %transparent-bg;
                &.disabled {
                    pointer-events: none;
                    opacity: .5;
                }
            }
        }
        &-wrap {
            height: 100%;
            @extend %flex-column;
            @extend %h-100;
        }
        &-footer {
            margin-top: auto;
        }
    }
    .select-list {
        .list-item {
            .list-item-text {
                text-transform: capitalize;
            }
            &.active {
                .list-item-text {
                    font-weight: 700;
                }
            }
        }
    }
    .filter-section {
        top: var(--space-7);
        .btn-more {
            @extend %primary-300-bg;
            .btn-text {
                @extend %capitalize;
                @extend %font-12-pb;
                @extend %spider-3s;
            }
            &::before {
                @extend %spider-3s;
                @include icon(product-filter, 14);
            }
        }
    }
    .more-filters {
        z-index: var(--z-shop-filter);
        .btn-cancel {
            &::after {
                @include icon(close-circle, 20);
            }
        }
        .head {
            .title {
                @extend %relative;
                @extend %pl-6;
                &::before {
                    left: 0;
                    @include icon(product-filter, 16);
                    @extend %spider-3s;
                    @extend %pos-y-center;
                }
            }
        }
        .body {
            @extend %p-8-4;
            .selected-title {
                border-radius: 0.5rem;
                @extend %neutral-50-bg;
                @extend %pr-0;
                @extend %p-3;
                .title {
                    @extend %d-none;
                }
                .sub-title {
                    @extend %capitalize;
                    @extend %font-14-pm;
                }
            }
            .waf-select-box {
                padding: unset;
                .select-box-wrap {
                    border-top: unset;
                }
                .checkbox-wrapper {
                    cursor: pointer;
                    user-select: none;
                    @extend %relative;
                    @extend %flex-fs-c;
                    @extend %gap-2;
                    input {
                        cursor: pointer;
                        opacity: 0;
                        height: 0;
                        width: 0;
                        @extend %absolute;
                        &:checked {
                            ~ {
                                .checkmark {
                                    @extend %neutral-800-bg;
                                    // transform: scale(0.8);
                                    scale: 0.8;
                                    &:after {
                                        width: 0.5rem;
                                        height: 1rem;
                                        border: solid var(--pure-white-900);
                                        border-width: 0 0.2rem 0.2rem 0;
                                        rotate: 45deg;
                                        @include position(0.1rem, 0, 0, 0.5rem);
                                        @extend %d-block;
                                    }
                                }
                            }
                        }
                    }
                    input:not(:checked) ~ .checkmark {
                        // transform: scale(1); 
                        scale: 1;
                    }
                }
                .checkmark {
                    height: 1.6rem;
                    width: 1.6rem;
                    transition: scale 0.5s ease, background-color 0.3s ease;
                    @include border(1, neutral-800);
                    &:after {
                        content: "";
                        @extend %absolute;
                        @extend %d-none;
                    }
                }
                &.active {
                    .sub-title {
                        font-weight: 700;
                    }
                }
                &::before {
                    content: unset;
                }
            }
            .waf-select-box + .waf-select-box {
                @extend %mt-3;
            }
        }
        .footer {
            box-shadow: unset;
            .btn {
                @extend %gap-2;
                @extend %flex-n-c;
                @extend %half-radius;
                @extend %capitalize;
                @extend %font-12-pb;
                &:after {
                    font-weight: 500;
                    width: 1.4rem;
                    @include icon(drop-arrow, 12);
                }
                &::before {
                    width: 1.4rem;
                    height: .1rem;
                }
            }
        }
    }
    .loadmore-wrap {
        @extend %d-none;
    }
    .no-data-wrap {
        @include flex-config(flex, null, center, center);
        @extend %w-100;
        .no-data {
            p {
                @extend %my-3;
                @extend %font-20-pb;
            }
        }
        .btn-site {
            @extend %btn-cart;
            @extend %d-block;
            .btn-text {
                @extend %font-14-pb;
                @extend %capitalize;
                @extend %secondary-800;
            }
        }
    }
}
@include mq(col-md) {
    .waf-wp-shop-listing {
        .waf-select-box {
            background-color: transparent;
            height: unset;
            flex: unset;
            min-width: unset;
            .select-box-wrap {
                top: 2.5rem;
            }
        }
        .filter-section {
            .filter-wrap {
                display: none;
            }
            .sub-title {
                display: block;
            }
            .btn-more {
                top: 3rem;
                right: 4rem;
                width: unset;
                height: 3.3rem;
                border-radius: var(--half-radius);
            }
            &.active {
                .more-filters {
                    right: 0;
                    height: calc(100% - var(--header-height));
                    padding: 0;
                }
            }
        }
        .more-filters {
            width: 100%;
            transition: 300ms right;
            @include position(0, -100%, null, null, fixed);
            .waf-select-box {
                background-color: var(--pure-white-900-bg);
                .selected-title {
                    height: 4rem;
                }
            }
            .waf-select-box .select-list,
            .select-box-wrap {
                box-shadow: none;
                position: relative;
                padding: 0;
            }
            .select-box-wrap {
                top: 0;
                margin-top: var(--space-3);
                border-top: .1rem solid clr(neutral-900, 3);
                background-color: var(--pure-white-900-bg);
            }
            .head {
                height: 5.4rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-inline: var(--space-4);
            }
            .btn-cancel {
                display: flex;
                justify-content: unset;
                align-items: center;
            }
            .body {
                height: calc(100% - 12.2rem);
                overflow: auto;
                flex-direction: column;
                gap: var(--space-3);
                .waf-select-box + .waf-select-box {
                    margin-top: 0;
                }
            }
            .footer {
                display: flex;
                height: auto;
                .btn {
                    transition: .3s;
                    &::before {
                        content: '';
                        transition: .3s;
                        background-color: var(--neutral-900);
                    }
                    &::after {
                        width: 0;
                        overflow: hidden;
                        transition: .3s;
                    }
                    &:hover {
                        &::before {
                            width: 0;
                            overflow: hidden;
                        }
                        &::after {
                            width: 1.4rem;
                            @include icon(drop-arrow, 12);
                        }
                    }
                }
                .btn-applyfilter::after {
                    padding-left: 0;
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-wp-shop-listing {
        .layout-wrapper {
            display: grid;
            grid-template-columns: 30rem 1fr;
            gap: var(--space-4);
        }
        .waf-head {
            .head-wrap {
                padding-bottom: 0;
                .title {
                    width: unset;
                }
                .result-wrap {
                    margin-left: auto;
                }
            }
        }
        .filter-section {
            grid-row: span 2;
            padding-top: var(--space-7);
            align-items: flex-start;
            .btn-more {
                display: none;
            }
        }
        .more-filters {
            position: sticky;
            top: calc(var(--header-height) + var(--space-7));
            height: auto;
            padding: 0 var(--space-5) var(--space-4);
            border: .1rem solid var(--neutral-50);
            border-radius: var(--half-radius);
            .head {
                padding: var(--space-5) 0;
                height: auto;
                background-color: transparent;
                border-bottom: .1rem solid var(--neutral-50);
                .title {
                    text-transform: capitalize;
                    font-size: var(--space-5);
                    padding-left: var(--space-8);
                    &::before {
                        font-size: var(--space-5);
                    }
                }
            }
            .body {
                height: auto;
                padding: var(--space-5) 0;
            }
            .footer {
                padding-inline: 0;
            }
            .btn-cancel {
                display: none;
            }
        }
        .pagination-wrap {
            grid-area: 3 / 1 / 3 / 3;
        }
        .no-data-wrap {
            .btn-site {
                .btn-text {
                    font-size: 1.4rem;
                }
            }
        }
    }
}